<template>
    <div>
        <el-main>
            <!--<el-button type="primary" style="margin-bottom: 10px" size="small"
                @click="$router.push({ path: '/marketing/repairAdvertisingAdd' })">+添加广告</el-button>-->
            <!-- 表单 -->
            <el-form class="el-form-search" label-width="140px">
                <el-form-item label="店铺名称：">
                    <el-input size="small" placeholder="请输入店铺名称" v-model="searchForm.store_name"></el-input>
                </el-form-item>
                <el-form-item label="商家姓名：">
                    <el-input size="small" placeholder="请输入商家姓名" v-model="searchForm.merchant_name"></el-input>
                </el-form-item>
                <el-form-item label="商家电话：">
                    <el-input size="small" type="number" placeholder="请输入商家电话"
                        v-model="searchForm.merchant_mobile"></el-input>
                </el-form-item>
                <el-form-item label="上架时间：">
                    <el-date-picker size="small" v-model="searchForm.start_show_time" type="daterange" range-separator="~"
                        start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                </el-form-item>
                <el-form-item label="下架时间：">
                    <el-date-picker size="small" v-model="searchForm.start_out_time" type="daterange" range-separator="~"
                        start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                </el-form-item>
                <el-form-item label="状态：">
                    <el-select v-model="searchForm.status" size="small" placeholder="请选择" clearable>
                        <el-option label="全部" :value="0"></el-option>
                        <el-option label="上架" :value="1"></el-option>
                        <el-option label="下架" :value="-1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label=" " label-width="25px">
                    <el-button type="primary" size="small" @click="search">搜索</el-button>
                    <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
                </el-form-item>
            </el-form>
            <!-- 列表 -->
            <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" :key="key">
                <template slot="empty">
                    <No />
                </template>
                <el-table-column width="120" align="center">
                    <template #header>
                        <div class="edit">
                            <span>排序</span>
                            <i class="el-icon-edit-outline"></i>
                        </div>
                    </template>
                    <template slot-scope="scope">
                        <el-input type="number" :min="0" size="small" v-model="scope.row.sort"
                            @change="setSort(scope.row, 1)"></el-input>
                    </template>
                </el-table-column>
                <el-table-column prop="u_mobile" label="用户手机" width="110" align="center"></el-table-column>
                <el-table-column prop="store_name" label="店铺名称" width="120" align="center"></el-table-column>
                <el-table-column prop="merchant_name" label="商家姓名" width="100" align="center"></el-table-column>
                <el-table-column prop="merchant_mobile" label="商家电话" width="110" align="center"></el-table-column>
                <el-table-column prop="city_name" label="上架城市" align="center"></el-table-column>
                <el-table-column prop="type" label="广告类型" width="120" align="center">
                    <template v-slot="{ row }">
                        <span>
                            {{ row.type == 1 ? '图片' : '视频' }}
                        </span>
                        <el-button v-if="row.type == 2" @click="handleVideo(row.ad_video)" type="text"
                            size="small">查看</el-button>
                    </template>
                </el-table-column>
                <el-table-column prop="ad_picture" label="广告图片" align="center">
                    <template v-slot="{ row }">
                        <div class="demo-image__preview">
                            <el-image style="width: 100px; height: 50px" :src="row.ad_picture"
                                :preview-src-list="[row.ad_picture]"></el-image>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="type" label="VR链接" width="260" align="center">
                    <template v-slot="{ row }">
                        <el-link type="primary" v-if="row.type == 1" :href="row.vr_url" target="_blank">{{ row.vr_url
                        }}</el-link>
                        <span v-else>视频</span>
                    </template>
                </el-table-column>
                <el-table-column prop="show_time" label="上架时间" width="180" align="center">
                    <template v-slot="{ row }">{{ getDateformat(row.show_time) }}</template>
                </el-table-column>
                <el-table-column prop="out_time" label="下架时间" width="180" align="center">
                    <template v-slot="{ row }">{{ getDateformat(row.out_time) }}</template>
                </el-table-column>
                <el-table-column label="展示状态" align="center" min-width="120">
                    <template slot-scope="scope">
                        <span>{{ scope.row.ad_status == 1 ? '未上架' : scope.row.ad_status == 2 ? '展示中' : '已到期' }}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" width="100">
                    <template #header>
                        <div class="edit">
                            <span>状态</span>
                            <i class="el-icon-edit-outline"></i>
                        </div>
                    </template>
                    <template slot-scope="scope">
                        <el-button type="info" plain v-if="scope.row.status == -1" size="small"
                            @click="setStatus(scope.row, scope.$index)">下架</el-button>
                        <el-button type="primary" v-else size="small"
                            @click="setStatus(scope.row, scope.$index)">上架</el-button>
                    </template>
                </el-table-column>
                <el-table-column align="center" width="100" label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" @click="handleEdit(scope.row)">编辑</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-dialog title="视频" :visible.sync="video_show" width="600px">
                <video :src="video" style="width:400px;height:300px;" controls></video>
            </el-dialog>
            <!-- 翻页 -->
            <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData">
            </Paging>
        </el-main>
    </div>
</template>

<script>

import _ from 'lodash';
import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate';

export default {
    components: {
        Paging,
    },
    data () {
        return {
            list: [],
            total_number: 0,
            searchForm: {
                page: 1,
                rows: 10,
                store_name: '',
                merchant_name: '',
                merchant_mobile: '',
                start_show_time: '',
                end_show_time: '',
                start_out_time: '',
                end_out_time: '',
                status: 0,
            },
            video: '',
            video_show: false
        };
    },
    created () {
        this.getList();
    },
    methods: {
        setSort (row) {
            this.$axios.post(this.$api.repair.Ad.setSort, {
                id: row.id,
                sort: row.sort,
            }).then(res => {
                if (res.code == 0) {
                    this.$message.success('保存成功');
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        setStatus (row, index) {
            this.$confirm(row.status == 1 ? '请确认是否下架' : '请确认是否上架', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$axios
                    .post(this.$api.repair.Ad.setStatus, {
                        id: row.id,
                        status: row.status == 1 ? -1 : 1,
                    })
                    .then(res => {
                        if (res.code == 0) {
                            this.list[index].status = row.status == 1 ? -1 : 1;
                            this.$message({
                                message: row.status ? '已上架' : '已下架',
                                type: 'success',
                            });
                            this.getList();
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
            });
        },
        search () {
            this.searchForm.page = 1;
            this.getList();
        },
        clearSearch () {
            this.searchForm = {
                page: 1,
                rows: 10,
                store_name: '',
                merchant_name: '',
                merchant_mobile: '',
                start_show_time: '',
                end_show_time: '',
                start_out_time: '',
                end_out_time: '',
                status: 0,
            };
            this.getList();
        },
        getDateformat (val) {
            return getDateformat(val);
        },
        updateData (val, status) {
            if (status == 0) {
                this.searchForm.rows = val;
                this.getList();
            } else {
                this.searchForm.page = val;
                this.getList();
            }
        },
        dealSearchData () {
            let searchForm = _.cloneDeep(this.searchForm);
            if (searchForm.start_show_time) {
                searchForm.end_show_time = searchForm.start_show_time[1] / 1000;
                searchForm.start_show_time = searchForm.start_show_time[0] / 1000;
            }
            if (searchForm.start_out_time) {
                searchForm.end_out_time = searchForm.start_out_time[1] / 1000;
                searchForm.start_out_time = searchForm.start_out_time[0] / 1000;
            }
            return searchForm;
        },
        // 获取列表
        getList () {
            let searchForm = this.dealSearchData();
            this.$axios.post(this.$api.repair.Ad.list, searchForm).then(res => {
                if (res.code == 0) {
                    this.list = res.result.list;
                    this.total_number = res.result.total_number;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        handleVideo (ad_video) {
            this.video = ad_video
            this.video_show = true
        },
        handleEdit (row) {
            this.$router.push({ path: '/marketing/repairAdvertisingEdit', query: { info: JSON.stringify(row) } })
        }
    },
};
</script>

<style lang="less" scoped>
.el-main {
    background: #fff;
}
</style>
